import { Card, chakra, Container, Flex } from '@chakra-ui/react';
import { TypeAnimation } from 'react-type-animation';
 
export default function SearchDemo() {
    const demoPhrases = [
        'I am looking for the best books on leadership',
        'I want to learn how to build habits',
        'I want to communicate more effectively',
        'I am looking for books to become a better programmer',
        'I am looking for the best business books',
    ]

    const getSequence = () => {
        const PAUSE_INTERVAL = 3000
        const sequence = [];
        const completionFunction = () => {}
        for(const demoPhrase of demoPhrases) {
            sequence.push(demoPhrase)
            sequence.push(PAUSE_INTERVAL)
        }
        sequence.push(completionFunction)
        return sequence
    }

    const ChakraTypeAnimation = chakra(TypeAnimation, {cursor:true})
    return (
        <Flex direction="column" align="center">
            <Container>
            <Card          
                minHeight="100px"
                padding={{base: 2, sm: 4}}
                margin={{base:4, md:8}}
                variant="filled"
                >
                <ChakraTypeAnimation
                    sequence={getSequence()}
                    wrapper="span"
                    repeat={Infinity}
                    deletionSpeed={70}
                    fontSize={{base:"md", sm:"lg", lg:"xl"}}
                    color="grey"
                />
            </Card>

            </Container>
        </Flex>
    );
};